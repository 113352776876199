import React, { useState, useEffect, useCallback } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';


export const DDashboard = () =>  {
    const navigate = useNavigate();
    const location = useLocation();
  
    const getActiveButtonFromURL = useCallback(() => {
      const path = location.pathname;
      const activeButton = path.substring(12);
      return activeButton || 'home';
    }, [location]);
  
    const [activeButton, setActiveButton] = useState(getActiveButtonFromURL());
  
    const handleLogout = () => {
      localStorage.clear()
      navigate('/login');
    };
  
    useEffect(() => {
      setActiveButton(getActiveButtonFromURL());
    }, [getActiveButtonFromURL, location]);
    
    return (
      <div className='bg'>
        <div className='bt-t' style={{ backgroundColor: 'white', textAlign: "left", display: "flex", justifyContent: "space-between", marginBottom: ' 10px'}}>
          <div className="logo">
            <div className="left" style={{ color: "rgba(16, 12, 196, 0.76)" }}>Q</div>
            <div className="right">
              <div style={{ color: "rgba(16, 12, 196, 0.76)" }}>給与明細システム</div>
            </div>
          </div>
          <Link to= '/login' className='btn btn-link' onClick={handleLogout} style={{ fontSize: "30px" }}><i className="fa-solid fa-arrow-right-from-bracket"></i><p style={{ fontSize: "10px" }}>ログアウト</p></Link>
        </div>
  
        <div className='row'>
          <div className='col-2 bt-v' style={{ backgroundColor: 'white', height: '100vh' }}>
            <ul className="nav nav-pills flex-column mb-auto">
              <li className="nav-item">
                <Link to="home">
                  <button
                    className={`bo-d btn btn-default ${activeButton === 'home' ? 'active' : ''}`}
                    data-toggle="button"
                    autoComplete="off"
                    style={{ width: "100%" }}
                  >
                    <i className="fa-solid fa-house" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> ホーム
                  </button>
                </Link>
              </li>
              <li>
                
                <Link to="kigyou">
                  <button
                    className={`bo-d ${activeButton === 'kigyou' ? 'active' : ''}`}
                    data-toggle="button"
                    autoComplete="off"
                    style={{ width: "100%" }}
                   
                  >
                    <i className="fa-solid fa-user" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> 企業一覧
                  </button>
                </Link>
              </li>
              <li>
                <Link to="shuunyuu">
                  <button
                    className={`bo-d btn btn-default ${activeButton === 'shuunyuu' ? 'active' : ''}`}
                    data-toggle="button"
                    autoComplete="off"
                    style={{ width: "100%" }}
             
                  >
                    <i className="fa-solid fa-hand-holding-dollar" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> 収入確認
                  </button>
                </Link>
              </li>
              <li>
                <Link to="touroku">
                  <button
                    className={`bo-d btn btn-default ${activeButton === 'touroku' ? 'active' : ''}`}
                    data-toggle="button"
                    autoComplete="off"
                    style={{ width: "100%" }}
     
                  >
                    <i className="fa-solid fa-folder-plus" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> 企業登録
                  </button>
                </Link>
              </li>
              <div className="animation start-home"></div>
            </ul>
          </div>
          <div className='col-10'><Outlet /></div>
        </div>
      </div>
  
    )
  }