import { useState } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import baseURL from './config.js';


export const Register = () => {
    const [name, setName] = useState('');
    const [password, setpassword] = useState('');

    const handleRegister = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
          event.stopPropagation();
        } else {
        Axios.post(`${baseURL}/api/register`, {
          name: name,
          password: password
        }).then((response) => {
          const successAlert = document.createElement('div');
          successAlert.classList.add('alert', 'alert-success');
          successAlert.textContent = '登録できました';
          document.body.appendChild(successAlert);
          setTimeout(() => {
            document.body.removeChild(successAlert);
          }, 3000);

          window.location.href="/"
    
          console.log(response);
        }).catch((error) => {
          // Handle form submission error
          console.error(error);
        });
      }
    
      form.classList.add('was-validated');
    }
    return (
        <div className='App'>
            <div className='bg'>
                <div className='fcon' style={{ width:"40%" , margin:"20px auto" , padding:"20px 7%"}}>
                    <h4>ログイン</h4>
                    <form className='needs-validation' noValidate onSubmit={handleRegister}>
                        <div>
                            <label htmlFor='name' className='form-label'>ID</label>
                            <input type='text' className='form-control' name='name' onChange={(e) => setName(e.target.value)} required></input>
                            <div className='valid-feedback'>Looks good!</div>
                            <div className='invalid-feedback'>Please write a valid input.</div>
                        </div>
                        <div style={{ paddingTop:"20px"}}>
                            <label htmlFor='password' className='form-label'>パスワード</label>
                            <input type='text' className='form-control' name='password' onChange={(e) => setpassword(e.target.value)} required></input>
                            <div className='valid-feedback'>Looks good!</div>
                            <div className='invalid-feedback'>Please write a valid input.</div>
                        </div>
                    </form>
                    <Link to="/"><button onClick={handleRegister} style={{padding:"10px 30px",margin:"0"}}>登録</button></Link>
                </div>
                <p className='cl'>Copyright 2023 Be-i.co,Ltd.</p>
                <p className='cl'>お問い合わせ先:<i className="fa-sharp fa-solid fa-phone"></i>03-6435-1785<i className="fa-solid fa-envelope"></i>info@be-i.co.jp</p>
            </div>
        </div>
    )
}
