import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Axios from 'axios';
import baseURL from '../config';

export const DKigyouTouroku = () => {
  const [kigyouname, setKigyouname] = useState('');
  const [yuubinbangou, setYuubinbangou] = useState('');
  const [juusho, setJuusho] = useState('');
  const [kaishibi, setKaishibi] = useState('');
  const [tel, setTel] = useState('');
  const [plan, setPlan] = useState('');

const handleFormSubmit = (e) => {
  e.preventDefault();

  const newEmployee = {
    kigyouname,
    yuubinbangou,
    juusho,
    kaishibi,
    tel,
    plan,
  };

  Axios.post(`${baseURL}/users`, newEmployee)
    .then((response) => {
      console.log(response.data);
      // Success handling code

      // Reset the form input values
      setKigyouname('');
      setYuubinbangou('');
      setJuusho('');
      setKaishibi('');
      setTel('');
      setPlan('');
    })
    .catch((error) => {
      console.log(error);
      // Error handling code
    });
};

  return (
    <div>
      <div className="serchfield">
        <Modal.Title>
          <h2 className="kigyo fo-t">企業登録</h2>
        </Modal.Title>
      </div>
      <div className="row bc m-3">
        <div className="bar"></div>
        <form id="myForm" onSubmit={handleFormSubmit}>
          <table>
            <tbody>
              <tr className="row m-2">
                <td className="col-2 "></td>
                <td className="col-2 p-2 label">
                  <Form.Label>企業名</Form.Label>
                </td>
                <td className="col-8 p-2 iptfield">
                  <Form.Group controlId="kigyouname">
                    <Form.Control
                      className="ipt"
                      type="text"
                      value={kigyouname}
                      onChange={(e) => setKigyouname(e.target.value)}
                    />
                  </Form.Group>
                </td>
                <td className="col-2 "></td>
                <td className="col-2 p-2 label">
                  <Form.Label>郵便番号</Form.Label>
                </td>
                <td className="col-8 p-2 iptfield">
                  <Form.Group controlId="yuubinbangou">
                    <Form.Control
                      className="ipt"
                      type="text"
                      value={yuubinbangou}
                      onChange={(e) => setYuubinbangou(e.target.value)}
                    />
                  </Form.Group>
                </td>
                <td className="col-2 "></td>
                <td className="col-2 p-2 label">
                  <Form.Label>住所</Form.Label>
                </td>
                <td className="col-8 p-2 iptfield">
                  <Form.Group controlId="juusho">
                    <Form.Control
                      className="ipt"
                      type="text"
                      value={juusho}
                      onChange={(e) => setJuusho(e.target.value)}
                    />
                  </Form.Group>
                </td>
                <td className="col-2 "></td>
                <td className="col-2 p-2 label">
                  <Form.Label>開始日</Form.Label>
                </td>
                <td className="col-8 p-2 iptfield">
                  <Form.Group controlId="kaishibi">
                    <Form.Control
                      className="ipt"
                      type="text"
                      value={kaishibi}
                      onChange={(e) => setKaishibi(e.target.value)}
                    />
                  </Form.Group>
                </td>
                <td className="col-2 "></td>
                <td className="col-2 p-2 label">
                  <Form.Label>TEL</Form.Label>
                </td>
                <td className="col-8 p-2 iptfield">
                  <Form.Group controlId="TEL">
                    <Form.Control
                      className="ipt"
                      type="text"
                      value={tel}
                      onChange={(e) => setTel(e.target.value)}
                    />
                  </Form.Group>
                </td>
                <td className="col-2 "></td>
                <td className="col-2 p-2 label">
                  <Form.Label>プラン</Form.Label>
                </td>
                <td className="col-8 p-2 iptfield">
                  <Form.Group controlId="plan">
                    <Form.Control
                      className="ipt"
                      type="text"
                      value={plan}
                      onChange={(e) => setPlan(e.target.value)}
                    />
                  </Form.Group>
                </td>
              </tr>
            </tbody>
          </table>
          <Button variant="primary" type="submit">登録</Button>
        </form>
      </div>
    </div>
  );
};