import React from 'react'
import { useState,useEffect, useCallback } from "react";
import { Link, Outlet, useLocation } from 'react-router-dom'
import Axios from 'axios'
import baseURL from './config.js';

// import { useAuth } from './Auth'



export const Dashboard = () => {
  const[userList, setUserList] = useState([])


    // const { setAuth } = useAuth();

  const location = useLocation();

  const getActiveButtonFromURL = useCallback(() => {
    const path = location.pathname;
    const activeButton = path.substring(1);
    return activeButton || 'account';
  }, [location]);

  const [activeButton, setActiveButton] = useState(getActiveButtonFromURL());
    
  // const handleLogout = () => {
  //   localStorage.clear()
  //   setAuth(null);

  // };
  useEffect(() => {
    setActiveButton(getActiveButtonFromURL());
  }, [getActiveButtonFromURL, location]);

  useEffect(() => {
    Axios.get(`${baseURL}/api/login`)
      .then((response) => {
        const { users } = response.data;
        setUserList(users);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className='bg'>
      <div className='bt-t' style={{ backgroundColor: 'white', textAlign: "left", display: "flex", justifyContent: "space-between", marginBottom: ' 10px'}}>
        <div className="logo">
          <div className="left" style={{ color: "rgba(16, 12, 196, 0.76)" }}>Q</div>
          <div className="right">
            <div style={{ color: "rgba(16, 12, 196, 0.76)" }}>給与明細システム</div>
          </div>
        </div>
        {/* <Link to= '/login' className='btn btn-link' onClick={handleLogout} style={{ fontSize: "30px" }}><i className="fa-solid fa-arrow-right-from-bracket"></i></Link> */}
      </div>

      
      <div className='row'>
        <div className='col-2 bt-v' style={{ backgroundColor: 'white', height: '100vh' }}>
          <ul className="nav nav-pills flex-column mb-auto">
          <li>
              <h4 style={{ textAlign: "center" }}>Admin画面</h4>
              <hr />
            </li>
            <li className="nav-item">
              <Link to="account">
                <button
                  className={`bo-d btn btn-default ${activeButton === 'account' ? 'active' : ''}`}
                  data-toggle="button"
                  autoComplete="off"
                  style={{ width: "100%" }}
                >
                  <i className="fa-solid fa-house" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> アカウント
                </button>
              </Link>
            </li>
            <li>
        
              <Link to="seikyuusho">
                <button
                  className={`bo-d ${activeButton === 'seikyuusho' ? 'active' : ''}`}
                  data-toggle="button"
                  autoComplete="off"
                  style={{ width: "100%" }}
                 
                >
                  <i className="fa-solid fa-user" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> 請求書
                </button>
              </Link>
            </li>
            <li>
              <Link to="dairiten">
                <button
                  className={`bo-d btn btn-default ${activeButton === 'dairiten' ? 'active' : ''}`}
                  data-toggle="button"
                  autoComplete="off"
                  style={{ width: "100%" }}
           
                >
                  <i className="fa-solid fa-hand-holding-dollar" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> お支払明細
                </button>
              </Link>
            </li>
            <li>
              <Link to="koudourireki">
                <button
                  className={`bo-d btn btn-default ${activeButton === 'koudourireki' ? 'active' : ''}`}
                  data-toggle="button"
                  autoComplete="off"
                  style={{ width: "100%" }}
   
                >
                  <i className="fa-solid fa-sack-dollar" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> 操作ログ
                </button>
              </Link>
            </li>

            <div className="animation start-home"></div>
          </ul>
        </div>
        <div className='col-10'><Outlet /></div>
      </div>
    </div>
  )
}
