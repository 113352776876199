import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom'
import baseURL from '../config.js';

export const DShuunyuu = () => {
  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const kingaku = 70000;


  useEffect(() => {
    Axios.get(`${baseURL}/api/dshuunyuu`)
      .then((response) => {
        const { sousa } = response.data;
        setUserList(sousa);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filteredUserList = userList.filter((val) => {
    const { hakkoudate, hakkouBangou, kingaku, } = val;
    const searchRegex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    return (
      (searchTerm === '' || searchRegex.test(hakkoudate) || searchRegex.test(hakkouBangou) || searchRegex.test(kingaku))
    );
  })

  return (
    <div>
      <div class="serchfield">
      <h2 class="kigyo fo-t">収入確認</h2>
      </div>
      <div class="row bc m-3">
        <div class="searchbar">
      <input type="search" class='search bt-s' placeholder="検索" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
      </div>
      <tr class="row m-2">
      <td class="title col-4 text-center">日付</td>
      <td class="title col-2 text-center">発行番号</td>
      <td class="title col-2 text-center">金額</td>
      <td class="col-4"></td>
      </tr>
      <tbody>
      {filteredUserList.reverse().map((val, index) => {
        return(
      <tr  key={val.id} class="row m-2">
      <td class="col-4 text-center">{val.hakkoudate}</td>
      <td class="col-2 text-center">{val.hakkouBangou}</td>
      <td class="col-2 text-center">{kingaku}</td>
      <td class="col-4 text-center">
      <Link to={`/ddashboard/shuunyuu/shuunyuushousai?id=${encodeURIComponent(val.id)}`}>
        <button className="Details">詳細</button>
      </Link>
      </td>
      </tr>
         );
      })}
      </tbody>
      </div>
      <div className='col-10'></div>
    </div>
  )
}
