import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import baseURL from '../config.js';

export const DHome = () => {
  const [koushinList, setKoushinList] = useState([])

  useEffect(() => {
    Axios.get(`${baseURL}/api/dhome`)
      .then((response) => {
        const { sousa } = response.data;
        setKoushinList(sousa);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div class="p-0">
      <h2 class="kigyo fo-t">アカウント</h2><br></br>
      <h3 class="kigyo">ログ</h3>
      <tr class="row">
        <td class="col-6 p-0 text-center">ログ詳細</td>
        <td class="col-6 text-center day">日付</td>
        </tr>
          {koushinList.reverse().map((val, index) => {
            return (
              <tr key={val.id} class="row rogsho m-3">
                <td class="col-6 text-center">{val.shousai}</td>
                <td class="col-6 text-center">{val.date}</td>
              </tr>
            );
          })}
    </div>
  )
}