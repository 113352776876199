import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Axios from 'axios'
import moment from 'moment'
import baseURL from './config.js';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/ja'

export const Dairiten = () => {
  
  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [specificDay, setSpecificDay] = useState('');
  const [specificTime, setSpecificTime] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    Axios.get(`${baseURL}/api/get`)
      .then((response) => {
        const { dairi } = response.data;
        setUserList(dairi);
   
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filteredUserList = userList.filter((val) => {
    const { username } = val;
    const searchRegex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    return (
      (searchTerm === '' || searchRegex.test(username) )
    );
  })


  function handleHakkou() {

    // Check if specific date and time are provided, otherwise use the default ones
    const selectedDay = specificDay;
    const selectedTime = specificTime;
    const selectedDateTime = moment(`${selectedDay} ${selectedTime}`).format('YYYY-MM-DD HH:mm');

    let cronSchedule;
    cronSchedule = moment(`${selectedDay} ${selectedTime}`).format('mm HH DD MM *');
    Axios.post(`${baseURL}/api/system/seikyuu/schedule`, { selectedDateTime })
      .then((response) => {
        console.log('Schedule successful:', response.data);
        // You can perform any additional actions here upon successful scheduling.
      })
      .catch((error) => {
        console.error('Error scheduling:', error.response.data);
        // Handle the error or display an error message to the user.
      });

      const payload = {
        selectedDateTime: cronSchedule
      };
  
      Axios.post(`${baseURL}/api/seikyuusho/rireki`, payload)
        .then((response) => {
           // Handle successful form submission
        const successAlert = document.createElement('div');
        successAlert.classList.add('alert', 'alert-success');
        successAlert.textContent = '発行されました！';
        document.body.appendChild(successAlert);
        setTimeout(() => {
          document.body.removeChild(successAlert);
        }, 3000);
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

    setShowPopup(false);

  }

  function handleCancel() {
    // Close the modal
    setShowPopup(false);
  }

  const handleDateChange = (selectedDate) => {
    // When using react-datetime, the selected date is already provided as an argument
    // in the `handleDateChange` function, so we can directly use it to update the state.
    setSpecificDay(selectedDate.format('YYYY-MM-DD'));
  };

  const handleTimeChange = (selectedTime) => {
    // When using react-datetime, the selected time is already provided as an argument
    // in the `handleTimeChange` function, so we can directly use it to update the state.
    setSpecificTime(selectedTime.format('HH:mm'));
  };

  const isValidDate = (current) => {
    // Disable dates and times that have already passed
    return current.isAfter(moment().subtract(1, 'day')); // Allow only dates and times in the future
  };

  const handleButtonClick = () => {
    console.log('Button clicked');
    setShowPopup(true); // Set the showPopup state to true to show the Modal
  };

  return (
    <div>
      
        <h3 className='fo-t'>代理店</h3>
    <button
          className='bt-jc'
          style={{ float: 'right'}}
          onClick={handleButtonClick} // Add onClick event handler
        >発行タイマーセット</button> <br/>

        
      {showPopup && (
        <Modal show={showPopup} onHide={() => setShowPopup(false)} centered>
          <Modal.Header closeButton style={{ backgroundColor: '#DDEFFF', color: '#4f4f4f' }}>
            <Modal.Title>発行タイマーセット</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Default Day and Time Section */}

            <h5 style={{ marginTop: '10px', padding: '5px', textAlign: 'center' }}>発行日時を設定</h5>

            <Form>
              <Form.Group controlId="specificDatePicker">

                {/* Use react-datetime for date picking */}
                <Datetime
                  dateFormat="YYYY-MM-DD"
                  timeFormat={false}
                  value={specificDay ? specificDay : moment().format('YYYY-MM-DD')}
                  onChange={handleDateChange}
                  locale="ja" // Set the locale to Japanese
                  inputProps={{ placeholder: '日付を選択してください' }} // Set placeholder in Japanese
                  isValidDate={isValidDate} // Set the isValidDate prop
                />
              </Form.Group>

              <Form.Group controlId="specificTimePicker" style={{ marginTop: '10px' }}>
                {/* Use react-datetime for time picking */}
                <Datetime
                  dateFormat={false}
                  timeFormat="HH:mm"
                  value={specificTime ? specificTime : moment().format('HH:mm')}
                  onChange={handleTimeChange}
                  inputProps={{ placeholder: '時間を選択してください' }} // Set placeholder in Japanese
                  isValidDate={isValidDate} // Set the isValidDate prop
                />
              </Form.Group>
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleHakkou}>発行</Button>
            <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      )}
      
    <div className="row bc m-3">
      <div className="searchbar">
          <input type="search" className='search bt-s' placeholder="検索" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      <button className="filter"><i className="fas fa-filter"></i></button>
      </div>
      <table className='bt-r'>
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {filteredUserList.map((val, index) => {
          return (
            <tr key={val.id}>
              <td style={{padding:"10px"}}>{index + 1}</td>
              <td style={{padding:"10px"}}>{val.username}</td>
              <td style={{padding:"10px"}}>
                <Link to={`/meisai/rireki?username=${encodeURIComponent(val.username)}&dairi_pk=${encodeURIComponent(val.dairi_pk)}`}>
                    <button className='bt-h'>履歴</button></Link><br />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    </div>
    </div>
  );
}
