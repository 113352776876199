import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import baseURL from './config.js';


export const MeisaiHakkou = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const userId = queryParams.get('id')
    const [user, setUser] = useState([])
    const currentDate = moment().format('YYYY-MM-DD')
    const monthday = moment().format('MMDD');
    const [hakkouBangou, setHakkouBangou] = useState([])
    const [loader, setLoader] = useState(false);

    const downloadPDF = () => {
      const capture = document.getElementsByClassName('fcon')[0]
      setLoader(true)
      html2canvas(capture).then((canvas)=>{
        const imgData = canvas.toDataURL('img.//png')
        const doc = new jsPDF('p', 'mm', 'a4')
        const componentWidth = doc.internal.pageSize.getWidth()
        const componentHeight = doc.internal.pageSize.getHeight()
        doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight)
        setLoader(false)
        doc.save('meisai.pdf')
      })
    }


    useEffect(() => {
      Axios.get(`${baseURL}/api/shousai?id=${userId}`)
        .then((response) => {
          const { dairi } = response.data;
          setUser(dairi)
          setHakkouBangou(parseInt(monthday)+ dairi[0].id.toString())
        })
        .catch((error) => {
          console.log(error);
        });
    }, [userId, monthday]);

    const handleHakkouClick = () => {
      const payload = {
        userId: userId,
        user: user,
        hakkouBangou: hakkouBangou,
        currentDate: currentDate
      };
  
      Axios.post(`${baseURL}/api/meisai/rireki`, payload)
        .then((response) => {
           // Handle successful form submission
        const successAlert = document.createElement('div');
        successAlert.classList.add('alert', 'alert-success');
        successAlert.textContent = '発行されました！';
        document.body.appendChild(successAlert);
        setTimeout(() => {
          document.body.removeChild(successAlert);
        }, 3000);
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    };

  return (
    <div className="bo">
      <h3 className='fo-t'>明細書発行</h3>
      <Link to="/dairiten"><button className='bt-jc'>戻る</button></Link>
      <button style={{ float: 'right', marginRight: '0px' }} className='hakkou' onClick={handleHakkouClick}>発行</button>
      <button style={{ float: 'right', marginRight: '15px' }} className='pdf' onClick={downloadPDF} disabled={!(loader===false)}>{loader?(<span>Downloading</span>):(<span>PDF</span>)}</button>
      {user.map((val, index) => {
            return (
      <div className='fcon' key={val.id}>
        <h3 className='s-title'>お支払い明細</h3>
        <div className='row gx-2'>
          <div className='col-6'>
            <div className='border border-3 p-3 h-info'>
              <div>
                <h5>{val.username}</h5>
                <p className='s-hk'>〒{val.postcode}</p>
                <p className='s-hk'>{val.address}</p>
                <p className='s-hk'>発行番号{hakkouBangou}</p>
              </div>
            </div>
            </div>
            <div className='col-6'>
            <div className='border border-3 p-3 h-info'>
              <h5>有限会社Be-i</h5>
              <p className='s-hk'>京都府京都市</p>
              <p className='s-hk'>伏見区淀水垂町509-16</p>
              <p className='s-hk'>06(6943)1234</p>
            </div>
          </div>
          </div>
          <div className='row gx-2'>
          <div className='col-4'>
            <div className='border border-3 pe-3 ps-3 h-infos'>
              <h5>請求金額</h5>
            </div>
          </div>
          <div className='col-8'>
            <div className='border border-3 pe-3 ps-3 h-infos'>
              <h5>50000円</h5>
            </div>
          </div>
        </div>
        <div className='gx-2'>
          <div className='border border-3 p-4 h-infom'>
            <p>{currentDate}</p>
            <div className='s-si'>
              <h5>請求詳細</h5>
              <hr/>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>40000円</h5>
              </div>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>10000円</h5>
              </div>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>10000円</h5>
              </div>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>10000円</h5>
              </div>
              <hr />
              <div className='row'>
                <h5 className='col-6'>請求金額</h5>
                <h5 className='col-6'>70000円</h5>
              </div>
            </div>
            <p>発行者：石黒舞子</p>           
          </div>
        </div>
      </div>
      );
            })}
    </div>
  )
}
