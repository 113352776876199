import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios';
import baseURL from './config.js';


export const AddedUser = () => {
  const [setCompany_pk] = useState('');
  const [company_name, setCompany_name] = useState('');
  const [postcode, setPostcode] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  // const [plan, setPlan] = useState('シルバー');
  const [reg_dt, setRegdate] = useState('');
  const [role] = '3000';


  const handleZipCodeChange = async (event) => {
    const zipCode = event.target.value;
    if (zipCode.length === 7) {
      try {
        const response = await Axios.get(`${baseURL}/api/v3/postcodes/${zipCode}`);
        const { data } = response;
        setAddress(data.allAddress)
      } catch (error) {
        console.log('Failed to fetch address data:', error);
        setAddress('');
      }
    } else {
      setAddress('');
    }
  };

  const submitForm = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    } else {
      // ここでランダムな数字を生成してcompany_pkに設定
        const randomCompanyPk = Math.floor(Math.random() * 1000000); // 適宜範囲を調整
        setCompany_pk(randomCompanyPk);
      Axios.post(`${baseURL}/api/insert/user`, { 
        company_pk: randomCompanyPk,
        company_name: company_name,
        postcode: postcode,
        address: address,
        email: email,
        tel: tel,
        // plan: plan,
        reg_dt: reg_dt,   
        role: role,
      }).then((response) => {
        // Handle successful form submission
        const successAlert = document.createElement('div');
        successAlert.classList.add('alert', 'alert-success');
        successAlert.textContent = '登録できました';
        document.body.appendChild(successAlert);
        setTimeout(() => {
          document.body.removeChild(successAlert);
        }, 3000);

        console.log(response);
      }).catch((error) => {
        // Handle form submission error
        console.error(error);
      });
    }

    form.classList.add('was-validated');
  }
 

  return (
    <div className='bo'>
      <h3 className='fo-t'>ユーザー登録</h3>
      <Link to="/account"><button className='bt-jc'>戻る</button></Link>
      <div className='fcon'>
        <form className='needs-validation' noValidate onSubmit={submitForm}>
          <div>
            <label htmlFor='username' className='form-label'>企業名</label>
            <input type='text' className='form-control' name='username' onChange={(e) => setCompany_name(e.target.value)} required></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          <div>
            <label htmlFor='postcode' maxLength="8" minLength="8" className='form-label'>郵便番号</label>
            <input type='text' className='form-control' name='postcode' pattern="\d{3}-?\d{4}" minLength='7' maxLength='8'  onChange={(e) => {setPostcode(e.target.value); handleZipCodeChange(e);}}></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          <div>
            <label htmlFor='address' className='form-label'>住所</label>
            <input type='text' className='form-control' name='address' id='address' value={address} onChange={(e) => setAddress(e.target.value)}></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          <div>
            <label htmlFor='email' className='form-label'>EMAIL</label>
            <input type='email' className='form-control' name='email' onChange={(e) => setEmail(e.target.value)}></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          <div>
            <label htmlFor='tel' className='form-label'>電話番号</label>
            <input type='tel' maxLength="13" pattern="\d{2,4}-?\d{2,4}-?\d{3,4}" className='form-control' name='tel' onChange={(e) => setTel(e.target.value)} required></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          {/* <div>
            <label htmlFor='plan' className='form-label'>プラン</label>
            <select name="plan" className="form-control" onChange={(e) => setPlan(e.target.value)}>
              <option value="シルバー">シルバー</option>
              <option value="ゴールド">ゴールド</option>
            </select>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div> */}

          <div>
            <label htmlFor='reg_dt' className='form-label'>登録日</label>
            <input type='date' className='form-control' name='reg_dt' onChange={(e) => setRegdate(e.target.value)}></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>
          
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <button type='submit'>保存</button>
          </div>
        </form>
      </div>
    </div>
  )
}

