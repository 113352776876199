import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Axios from 'axios';
import baseURL from './config.js';


export const DairitenShousai = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('dairi_pk');
    const [dairi, setDairi] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editedUser, setEditedUser] = useState({});

    useEffect(() => {
        Axios.get(`${baseURL}/api/shousai?id=${userId}`)
            .then((response) => {
                const { dairi } = response.data;
                setDairi(dairi)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [userId]);

    const handleEditClick = () => {
        setEditMode(true);
        setEditedUser(dairi[0]);
    };

    const handleSaveClick = () => {
        Axios.put(`${baseURL}/api/update/dairi`, editedUser)
            .then((response) => {
                console.log(response);
                setDairi([editedUser])
                setEditMode(false)
            })
            .catch((error) => {
                console.log('Failed to update user:', error);
            });
    };

    const handleCancelClick = () => {
        console.log('Cancel clicked');
        setEditMode(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    return (
        <div className='bo'>
            <h3 className='fo-t'>代理店詳細</h3>

            {dairi.map((val, index) => {
                return (
                    <div className='fcon' key={val.dairi_pk}>
            <Link to="/account/dairi"><button className='bt-jc'>戻る</button></Link>
                        {!editMode ? (
                            <button
                            className='bt-jc'
                                style={{ float: 'right', marginRight: '0px' }}
                                onClick={handleEditClick}
                            >
                                編集
                            </button>
                        ) : (
                            <div style={{ float: 'right', marginRight: '0px' }}>
                                <button onClick={handleSaveClick}>保存</button>
                                <button onClick={handleCancelClick}>キャンセル</button>
                            </div>
                        )}
                        <h3 className='s-title'>{val.username}</h3>
                        <div style={{marginLeft:"150px"}}>
                            <div className='row'>
                                <div className='col-2 s-aida'>郵便番号</div>
                                <div className='col-10'>
                                    <input
                                        type='text'
                                        className={`s-aida ${editMode ? 'form-control' : 'test' }`}
                                        name='postcode'
                                        pattern="\d{3}-?\d{4}"
                                        minLength='7'
                                        maxLength='8'
                                        required
                                        readOnly={!editMode}
                                        value={editMode ? editedUser.postcode : val.postcode}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2 s-aida'>住所</div>
                                <div className='col-10'>
                                    <input
                                        type='text'
                                        className={`s-aida ${editMode ? 'form-control' : 'test' }`}
                                        name='address'
                                        readOnly={!editMode}
                                        value={editMode ? editedUser.address : val.address}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-2 s-aida'>予約日</div>
                                <div className='col-10'>
                                    <input
                                        type='text'
                                        className={`s-aida ${editMode ? 'form-control' : 'test' }`}
                                        name='regdate'
                                        readOnly={!editMode}
                                        value={editMode ? editedUser.regdate : val.reg_dt}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2 s-aida'>EMAIL</div>
                                <div className='col-10'>
                                    <input
                                        type='email'
                                        className={`s-aida ${editMode ? 'form-control' : 'test' }`}
                                        name='email'
                                        readOnly={!editMode}
                                        value={editMode ? editedUser.email : val.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2 s-aida'>電話番号</div>
                                <div className='col-10'>
                                    <input
                                        type='text'
                                        className={`s-aida ${editMode ? 'form-control' : 'test' }`}
                                        name='tel'
                                        readOnly={!editMode}
                                        value={editMode ? editedUser.tel : val.tel}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                );
            })}
        </div>
    )
}