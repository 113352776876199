import React from 'react'
import { Routes, Route } from "react-router-dom"
import { Dashboard } from './components/Dashboard'
import { Account } from './components/Account'
import { Seikyuusho } from './components/Seikyuusho'
import { Dairiten } from './components/Dairiten'
import { Koudourireki } from './components/Koudourireki'
import { AccountUser } from './components/AccountUser'
import { AccountDairi } from './components/AccountDairi'
import { AccountSeikyuusha } from './components/AccountSeikyuusha'
import { AddedUser } from './components/AddedUser'
import { AddedDairi } from './components/AddedDairi'
import { AddedSeikyuusha } from './components/AddedSeikyuusha'
import { MeisaiHakkou } from './components/MeisaiHakkou'
import { SeikyuushoRireki } from './components/SeikyuushoRireki'
import { MeisaiRireki } from './components/MeisaiRireki'
import { UserShousai } from './components/UserShousai'
import { DairitenShousai } from './components/DairitenShousai'
import { SeikyuushoHakkou } from './components/SeikyuushoHakkou'
import { SeikyuushaShousai } from './components/SeikyuushaShousai'
import { SeikyuushoRirekiShousai } from './components/SeikyuushoRirekiShousai'
import { MeisaiRirekiShousai } from './components/MeisaiRirekiShousai'
import { Register } from './components/Register'
import { DDashboard } from './components/dairiten/DDashboard'
import { DHome } from './components/dairiten/DHome'
import { DKigyou } from './components/dairiten/DKigyou'
import { DKigyouTouroku } from './components/dairiten/DKigyouTouroku'
import { DShuunyuu } from './components/dairiten/DShuunyuu'
import { DShuunyuuShousai } from './components/dairiten/DShuunyuuShousai'
import { DKigyouShousai } from './components/dairiten/DKigyouShousai'
import { DKigyoushinki } from './components/dairiten/DKigyoushinki'
import { Login } from './components/Login';
import { RequireAuth } from './components/RequireAuth';
import { AuthProvider } from './components/Auth';


function App() {
  return (
    <AuthProvider>
    <Routes>
        {/* <Route element={<RequireAuth />}> */}
        <Route path='/login' element={<Login />} />
      <Route path='/' element={<Dashboard />} >


        <Route path='/account' element={<Account />}>
          <Route index element={<AccountUser />} />
          <Route path='user' element={<AccountUser />} />
          <Route path='dairi' element={<AccountDairi />} />
          <Route path='seikyuusha' element={<AccountSeikyuusha />} />
        </Route>
        <Route path='/user/add' element={<AddedUser />} />
        <Route path='/dairi/add' element={<AddedDairi />} />
        <Route path='/seikyuusha/add' element={<AddedSeikyuusha />} />
        <Route path='/seikyuusho' element={<Seikyuusho />} />
        <Route path='/seikyuusho/hakkou' element={<SeikyuushoHakkou />} />
        <Route path='/dairiten' element={<Dairiten />} />
        <Route path='/koudourireki' element={<Koudourireki />} />
        <Route path='/seikyuusho/rireki' element={<SeikyuushoRireki />} />
        <Route path='/seikyuusho/rireki/shousai' element={<SeikyuushoRirekiShousai />} />
        <Route path='/meisai/rireki' element={<MeisaiRireki />} />
        <Route path='/meisai/rireki/shousai' element={<MeisaiRirekiShousai />} />
        <Route path='/meisai/hakkou' element={<MeisaiHakkou />} />
        <Route path='/seikyuusha/shosai' element={<SeikyuushaShousai />} />
        <Route path='/account/user/shousai' element={<UserShousai />} />
        <Route path='/account/dairi/shousai' element={<DairitenShousai />} />
        <Route path='/register' element={<Register />} />
      </Route>

      <Route path='/ddashboard' element={<DDashboard />} >
        <Route path='touroku' element={<DKigyouTouroku />} />
        <Route path='kigyou' element={<DKigyou />} />
        <Route path='kigyou/kigyoushinki' element={<DKigyoushinki />} />
        <Route path='kigyou/kigyoushousai' element={<DKigyouShousai />} />
        <Route path='shuunyuu' element={<DShuunyuu />} />
        <Route path='shuunyuu/shuunyuushousai' element={<DShuunyuuShousai />} />
        <Route path='home' element={<DHome />} />
      </Route>
      

      {/* </Route> */}

    </Routes>
    </AuthProvider>
  )
}

export default App;
