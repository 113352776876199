import React, { useState } from 'react';
import { Link, Outlet} from 'react-router-dom';

export const Account = () => {
  const [activeButton, setActiveButton] = useState('');

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <div className="bo">
      <h3 className="fo-t">アカウント管理</h3>
      <div
        className="row bt-r"
        style={{ width: '50%', backgroundColor: 'white', marginLeft: '1px', padding: '5px' }}
      >
        <div className={`col ${activeButton === 'user' ? 'active' : ''}`}>
          <div>
            <Link to="user">
              <button
                className={`col bo-u ${activeButton === 'user' ? 'active' : ''}`}
                onClick={() => handleButtonClick('user')}
              >
                <i className="fa-regular fa-user" style={{ color: 'rgba(16, 12, 196, 0.76)' }}></i> 利用企業
              </button>
            </Link>
          </div>
        </div>

        <div className="col-1">
          <h2 style={{ fontWeight: 'lighter' }}>|</h2>
        </div>

        <div className={`col ${activeButton === 'dairi' ? 'active' : ''}`}>
          <div>
            <Link to="dairi">
              <button
                className={`col bo-u ${activeButton === 'dairi' ? 'active' : ''}`}
                onClick={() => handleButtonClick('dairi')}
              >
                <i className="fa-regular fa-user" style={{ color: 'rgba(16, 12, 196, 0.76)' }}></i> 代理店
              </button>
            </Link>
          </div>
        </div>

        <div className="col-1">
          <h2 style={{ fontWeight: 'lighter' }}>|</h2>
        </div>

        <div className={`col ${activeButton === 'seikyuusha' ? 'active' : ''}`}>
          <div>
            <Link to="seikyuusha">
              <button
                className={`col bo-u ${activeButton === 'seikyuusha' ? 'active' : ''}`}
                onClick={() => handleButtonClick('seikyuusha')}
              >
                <i className="fa-regular fa-user" style={{ color: 'rgba(16, 12, 196, 0.76)' }}></i> Admin
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};