import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import baseURL from './config.js';


export const Koudourireki = () => {

  const [koudoList, setKoudoList] = useState([])

  useEffect(() => {
    Axios.get(`${baseURL}/api/koudou`)
      .then((response) => {
        const { koudo } = response.data;
        setKoudoList(koudo);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className='bo'>
      <h3 className='fo-t'>動作ログ</h3>
      <table className='bt-r'>
        <thead>
          <tr>
            <th></th>
            <th>請求者</th>
            <th>行動</th>
            <th>日付</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {koudoList.reverse().map((val, index) => {
            let shousaiLink;

            if (val.action === "請求書発行") {
              shousaiLink = (
                <Link to={`/seikyuusho/rireki/shousai?hakkou=${encodeURIComponent(val.entry)}`}>
                  <button className='bt-h'>詳細</button>
                </Link>
              );
            } else if (val.action === "明細書発行") {
              shousaiLink = (
                <Link to={`/meisai/rireki/shousai?hakkou=${encodeURIComponent(val.entry)}`}>
                  <button className='bt-h'>詳細</button>
                </Link>
              );
            } else {
              shousaiLink = null; // Set to null if there's no matching action
            }
            return (
              <tr key={val.id}>
                <td style={{ padding: "10px" }}>{index + 1}</td>
                <td style={{ padding: "10px" }}>{val.name}</td>
                <td style={{ padding: "10px" }}>{val.action}</td>
                <td style={{ padding: "10px" }}>{val.date}</td>
                <td style={{ padding: "10px" }}>{shousaiLink}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}
