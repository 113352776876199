import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import baseURL from './config.js';



export const SeikyuushoRireki = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('company_pk');
  const userName = queryParams.get('company_name');
  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    Axios.get(`${baseURL}/api/rireki?id=${userId}`)
      .then((response) => {
        const { seikyuushoRireki } = response.data;
        setUserList(seikyuushoRireki);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);



  const filteredUserList = userList.filter((val) => {
    const { company_name } = val;
    const searchRegex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    return (
      (searchTerm === '' || searchRegex.test(company_name))
    );
  })


  return (
    <div className='bo'>
      
        <h3 className='fo-t'>{userName}</h3>
        <Link to="/seikyuusho"><button className='bt-jc'>戻る</button></Link>
  
      <div className='row bc m-3'>
        <div className="searchbar">
          <input type="search" className='search bt-s' placeholder="検索" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          <button className="filter"><i className="fas fa-filter"></i></button>
        </div>
        <table className='bt-r'>
          <thead>
            <tr>
            <th style={{fontSize:"20px"}}>日付</th>
            <th style={{fontSize:"20px"}}>発行番号</th>
            <th style={{fontSize:"20px"}}>金額</th>
            <th style={{fontSize:"20px"}}>ステータス</th>
            </tr>
          </thead>
          <tbody>
            {filteredUserList.reverse().map((val, index) => {
              return (
                <tr key={val.id}>
                <td style={{padding:"10px"}}>{moment(val.hakkoudate).format('YYYY-MM-DD')}</td>
                <td style={{padding:"10px"}}>{val.hakkouBangou}</td>
                <td style={{padding:"10px"}}>{val.kingaku}</td>
                <td style={{padding:"10px"}}>{val.status}</td>
                <td style={{padding:"10px"}}>
                  <Link to={`/seikyuusho/rireki/shousai?hakkou=${encodeURIComponent(val.hakkouBangou)}`}><button className='bt-h'>詳細</button></Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}