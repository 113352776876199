import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import baseURL from './config.js';


export const AccountSeikyuusha = () => {


  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState({});
  // const [showPopup, setShowPopup] = useState(false);


  useEffect(() => {
    Axios.get(`${baseURL}/api/get`)
      .then((response) => {
        const { seikyuusha } = response.data;
        setUserList(seikyuusha);

        const statusData = {};
        seikyuusha.forEach((item) => {
          statusData[item.id] = item.status;
        });
        setStatus(statusData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const deleteSeikyuusha = (admin_pk) => {
    Axios.delete(`${baseURL}/api/delete/seikyuusha/${admin_pk}`)

    setUserList(userList.filter(seikyuusha => seikyuusha.admin_pk !== admin_pk ));


  };

  const updateStatus = (admin_pk) => {
    const updatedStatus = status[admin_pk] === '活動中' ? '停止中' : '活動中';

    Axios.put(`${baseURL}/api/update/status`, {
      id: admin_pk,
      status: updatedStatus,
    })

    setStatus((prevState) => ({
      ...prevState,
      [admin_pk]: updatedStatus,
    }));

  };

  const filteredUserList = userList.filter((val) => {
    const { admin_username } = val;
    const searchRegex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    return (
      (searchTerm === '' || searchRegex.test(admin_username))
    );
  })

  return (
    <div className='bo'>
      <Link to='/seikyuusha/add'><button className='bt-csv' style={{ position: "absolute", right: 0, margin: '-60px 15px 0 0' }}><i class="fa-solid fa-plus" style={{color: "#ffffff"}}></i>登録</button></Link>

<div className='row bc m-3'>
<div className="searchbar">
  <input type="search" className='search bt-s' placeholder="検索" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
  <button className="filter"><i className="fas fa-filter"></i></button>
</div>
<table className='bt-r'>
  <thead>
    <tr>
      <th>Admin</th>
      {/* <th>Email</th> */}
        </tr>
      </thead>
      <tbody>
        {filteredUserList.map((val, index) => {
          return (
            <tr key={val.id}>
              <td className='tx-l'><i className="fa-solid fa-circle" style={{ color: "#19E415" }}></i>{val.admin_username}</td>
              {/* <td style={{padding:"10px"}}>{val.email}</td> */}
              <td style={{padding:"10px"}}>
                {/* <button onClick={togglePopup}>詳細</button> */}
                <button className='bt-h' style={{marginRight:"10px"}} onClick={() => deleteSeikyuusha(val.admin_pk)}>削除</button>
                <button className='bt-k' style={{ backgroundColor: status[val.admin_pk] === '活動中' ? '#218F5A' : '#FF4A4A' }} onClick={() => updateStatus(val.admin_pk)}>{status[val.admin_pk]}</button>
              </td>
            </tr>


              );
            })}
          </tbody>
        </table>

        {/* 
      {showPopup && (
    <div className="popup">
      <h2>Pop-up Content</h2>
      <p>This is the content of the pop-up.</p>
      <button onClick={togglePopup}>Close</button>
    </div>
)} */}


      </div>
    </div>

  );
}
