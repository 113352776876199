import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import baseURL from './config.js';


export const AccountDairi = () => {

  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    Axios.get(`${baseURL}/api/get`)
      .then((response) => {
        const { dairi } = response.data;
        setUserList(dairi);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteDairi = (id) => {
    Axios.delete(`${baseURL}/api/delete/dairi/${id}`)

    setUserList(userList.filter(dairi => dairi.id !== id));

  };

  const filteredUserList = userList.filter((val) => {
    const { username , status } = val;
    const searchRegex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    return (
      (searchTerm === '' || searchRegex.test(username)|| searchRegex.test(status) )
    );
  })

  return (
    <div className='bo'>
    <Link to='/dairi/add'><button className='bt-csv' style={{ position: "absolute", right: 0, margin: '-60px 15px 0 0' }}><i className="fa-solid fa-plus" style={{ color: "#ffffff" }}></i>登録</button></Link>

<div className='row bc m-3'>
  <div className="searchbar">
    <input type="search" className='search bt-s' placeholder="検索" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
    <button className="filter"><i className="fas fa-filter"></i></button>
  </div>
  <table className='bt-r'>
    <thead>
      <tr>
        <th>企業名</th>
        <th>ステータス</th>
        </tr>
      </thead>
      <tbody>
        {filteredUserList.map((val, index) => {
          return (
            <tr key={val.id}>
              <td className='tx-l'><i className="fa-solid fa-circle" style={{ color: "#19E415" }}></i>{val.username}</td>
              <td style={{padding:"10px"}}>{val.status}</td>
              <td style={{padding:"10px"}}>
                <button className='bt-h' style={{marginRight:"10px"}} onClick={() => deleteDairi(val.id)}>削除</button>
                <Link to={`/account/dairi/shousai?dairi_pk=${encodeURIComponent(val.dairi_pk)}`}><button className='bt-h'>基本情報</button></Link>
              </td>
            </tr>

              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
