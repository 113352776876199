import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Axios from 'axios';
import baseURL from '../config.js';

export const DKigyoushinki = () => {
  const [username, setUsername] = useState('');
  const [postcode, setPostcode] = useState('');
  const [address, setAddress] = useState('');
  const [regdate, setRegdate] = useState('');
  const [tel, setTel] = useState('');
  // const [plan, setPlan] = useState('');

  const handleZipCodeChange = async (event) => {
    const zipCode = event.target.value;
    if (zipCode.length === 7) {
      try {
        const response = await Axios.get(`https://apis.postcode-jp.com/api/v3/postcodes/${zipCode}`);
        const { data } = response;
        setAddress(data.allAddress)
      }catch (error) {
        console.error('Failed to fetch address data:', error.response || error.message);
        setAddress('');
      }
    } else {
      setAddress('');
    }
  };
  
  const submitForm = (event) => {

    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    } else {
    Axios.post(`${baseURL}/api/dkigyo`, {
      username: username,
      postcode: postcode,
      address: address,
      regdate: regdate,
      tel: tel,
      // plan: plan,
    }).then((response) => {
      // Handle successful form submission
      const successAlert = document.createElement('div');
      successAlert.classList.add('alert', 'alert-success');
      successAlert.textContent = '登録できました';
      document.body.appendChild(successAlert);
      setTimeout(() => {
        document.body.removeChild(successAlert);
      }, 3000);

      console.log(response);
    }).catch((error) => {
      // Handle form submission error
      console.error(error);
    });
  }

  form.classList.add('was-validated');
}


  return (
    <div>
      <div className="serchfield">
        <Modal.Title>
          <h2 className="kigyo fo-t">企業登録</h2>
        </Modal.Title>
      </div>
      <div className="row bc m-3">
        <form id="myForm" onSubmit={submitForm}>
              <div className="row m-2">
                  <div className='col-2'></div>
                  <label htmlFor='username' className='col-2 p-2 label'>企業名</label>
                  <input type='text' className='col-6 p-2 ipt' name='username' onChange={(e) => setUsername(e.target.value)} required></input>
                  <div className='valid-feedback'>Looks good!</div>
                  <div className='invalid-feedback'>Please write a valid input.</div>
                  
                  <div className='col-2'></div>
                  <label htmlFor='postcode' maxLength="8" minLength="8" className='col-2 p-2 label'>郵便番号</label>
                  <input type='text' className='col-6 p-2 ipt' name='postcode' pattern="\d{3}-?\d{4}" minLength='7' maxLength='8'  onChange={(e) => {setPostcode(e.target.value); handleZipCodeChange(e);}}></input>
                  <div className='valid-feedback'>Looks good!</div>
                  <div className='invalid-feedback'>Please write a valid input.</div>

                  <div className='col-2'></div>
                  <label htmlFor='address' className='col-2 p-2 label'>住所</label>
                  <input type='text' className='col-6 p-2 ipt' name='address' id='address' value={address} onChange={(e) => setAddress(e.target.value)}></input>
                  <div className='valid-feedback'>Looks good!</div>
                  <div className='invalid-feedback'>Please write a valid input.</div>

                  <div className='col-2'></div>
                  <label htmlFor='regdate' className='col-2 p-2 label'>開始日</label>
                  <input type='date' className='col-6 p-2 ipt' name='regdate' onChange={(e) => setRegdate(e.target.value)} required></input>
                  <div className='valid-feedback'>Looks good!</div>
                  <div className='invalid-feedback'>Please write a valid input.</div>

                  <div className='col-2'></div>
                  <label htmlFor='tel' className='col-2 p-2 label'>TEL</label>
                  <input type='tel' maxLength="13" pattern="\d{2,4}-?\d{2,4}-?\d{3,4}" className='col-6 p-2 ipt' name='tel' onChange={(e) => setTel(e.target.value)} required></input>
                  <div className='valid-feedback'>Looks good!</div>
                  <div className='invalid-feedback'>Please write a valid input.</div>

                  
                  {/* <div className='col-2'></div>
                  <label htmlFor='plan' className='col-2 p-2 label'>プラン</label>
                  <input type='text' className='col-6 p-2 ipt' name='plan' onChange={(e) => setPlan(e.target.value)} required></input>
                  <div className='valid-feedback'>Looks good!</div>
                  <div className='invalid-feedback'>Please write a valid input.</div> */}

                </div>
                <button type="submit" className="btn btn-primary">登録</button>
        </form>
      </div>
    </div>
  );
};