import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios';
import baseURL from './config.js';


export const AddedDairi = () => {
  const [setDairi_pk] = useState('');
  const [username, setUsername] = useState('');
  const [postcode, setPostcode] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [status] = 'online';
  const [reg_dt, setReg_dt] = useState('');
  const [role] = '2000';
 


  const submitForm = (event) => {

    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    } else {
      const randomDairiPk = Math.floor(Math.random() * 1000000); // 適宜範囲を調整
      setDairi_pk(randomDairiPk);
    Axios.post(`${baseURL}/api/insert/dairi`, {
      dairi_pk: randomDairiPk,
      username: username,
      postcode: postcode,
      address: address,
      email: email,
      tel: tel,
      status: status,
      reg_dt: reg_dt,
      role: role,
    }).then((response) => {
      // Handle successful form submission
      const successAlert = document.createElement('div');
      successAlert.classList.add('alert', 'alert-success');
      successAlert.textContent = '登録できました';
      document.body.appendChild(successAlert);
      setTimeout(() => {
        document.body.removeChild(successAlert);
      }, 3000);

      console.log(response);
    }).catch((error) => {
      // Handle form submission error
      console.error(error);
    });
  }

  form.classList.add('was-validated');
}
  
  return (
    <div className='bo'>
      <h3 className='fo-t'>代理店登録</h3>
      <Link to="/account/dairi"><button className='bt-jc'>戻る</button></Link>
      <div className='fcon'>
        <form className='needs-validation' noValidate onSubmit={submitForm}>
          
          <div>
            <label htmlFor='username' className='form-label'>企業名</label>
            <input type='text' className='form-control' name='username' onChange={(e) => setUsername(e.target.value)} required></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          <div>
            <label htmlFor='postcode' className='form-label'>郵便番号</label>
            <input type='text' className='form-control' name='postcode' minLength='7' maxLength='8' onChange={(e) => setPostcode(e.target.value)}></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          <div>
            <label htmlFor='address' className='form-label'>住所</label>
            <input type='text' className='form-control' name='address' onChange={(e) => setAddress(e.target.value)}></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>
          
          <div>
            <label htmlFor='email' className='form-label'>EMAIL</label>
            <input type='email' className='form-control' name='email' onChange={(e) => setEmail(e.target.value)}></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>
          
          <div>
            <label htmlFor='tel' className='form-label'>電話番号</label>
            <input type='tel' className='form-control' name='tel' onChange={(e) => setTel(e.target.value)}></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          <div>
            <label htmlFor='regdate' className='form-label'>予約日</label>
            <input type='date' className='form-control' name='reg_dt' onChange={(e) => setReg_dt(e.target.value)}></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>
          
          <div style={{ textAlign:'center', marginTop:'10px' }}>
            <button type='submit'>保存</button>
          </div>
        </form>
      </div>
    </div>
  )
}
