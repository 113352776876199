import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios';
import baseURL from './config.js';


export const AddedSeikyuusha = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('活動中');
  
  const submitForm = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    } else {
    Axios.post(`${baseURL}/api/insert/seikyuusha`, {
      name: name,
      email: email,
      status: status,
    }).then((response) => {
      const successAlert = document.createElement('div');
      successAlert.classList.add('alert', 'alert-success');
      successAlert.textContent = '登録できました';
      document.body.appendChild(successAlert);
      setTimeout(() => {
        document.body.removeChild(successAlert);
      }, 3000);

      console.log(response);
    }).catch((error) => {
      // Handle form submission error
      console.error(error);
    });
  }

  form.classList.add('was-validated');
}

  return (
    <div className='bo'>
      <h3 className='fo-t'>請求書登録</h3>
      <Link to="/account/seikyuusha"><button className='bt-jc'>戻る</button></Link>
      <div className='fcon'>
        <form className='needs-validation' noValidate onSubmit={submitForm}>

          <div>
            <label for='name' className='form-label'>Name</label>
            <input type='text' className='form-control' name='name' onChange={(e) => setName(e.target.value)} required></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          <div>
            <label for='email' className='form-label'>Email</label>
            <input type='text' className='form-control' name='email' minLength='7' maxLength='8' onChange={(e) => setEmail(e.target.value)}></input>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          <div>
            <label for="status" className="form-label">Status</label>
            <select name="status" className="form-control" onChange={(e) => setStatus(e.target.value)}>
              <option value="活動中">活動中</option>
              <option value="停止中">停止中</option>
            </select>
            <div className='valid-feedback'>Looks good!</div>
            <div className='invalid-feedback'>Please write a valid input.</div>
          </div>

          <div style={{ textAlign:'center', marginTop:'10px' }}>
            <button type='submit'>保存</button>
          </div>

        </form>
      </div>
    </div>
  )
}
