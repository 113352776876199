import { useState, useEffect, useContext, createContext } from "react";
import Axios from "axios";
import baseURL from './config.js';


const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    try {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    } catch (error) {
      console.error("エラーが発生しました:", error);
      // 必要に応じてユーザーにエラーを通知したり、
      // 状態をリセットしたりする処理をここに記述できます。
    }
  }, []);

  const login = async (admin_username, admin_password) => {
    try {
      const response = await Axios.post(`${baseURL}/api/login`, {
        admin_username,
        admin_password,
      });

      const userData = response.data.user;
      setUser(userData);
      localStorage.setItem("user", JSON.stringify(userData)); 
      
    } catch (error) {
      console.log("Login failed:", error);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user"); 
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};