import {Link, useLocation} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import baseURL from '../config.js';

export const DShuunyuuShousai = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('id');
  const [user, setUser] = useState([]);
  
  useEffect(() => {
    Axios.get(`${baseURL}/api/shousaik?id=${userId}`)
      .then((response) => {
        const { user } = response.data;
        setUser(user);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  return (
    <div class="do">
    <div class="serchfield">
    <h2 class="kigyo fo-t">収入確認詳細</h2>
    </div>
    <div class="row bc m-3">
    <div class="shousaibar">2023-06-13
    <Link to="/ddashboard/shuunyuu">
    <button className="bt-h" style={{float:'left', marginLeft:'15%', fontSize:'20px',height:'40px'}}>戻る</button>
    </Link>
      <p style={{paddingRight:'25%',marginBottom:'0'}}></p>
      </div>
      <table>
      <tbody>
        <div style={{padding:'20px'}}>
        <div className='row gx-2'>
          <div className='col-6'>
            <div className='border border-3 p-3 h-info'>
              <div>
                <p className='s-hk'>〒540-8450</p>
                <p className='s-hk'>京都府京都市伏見区淀水垂町509-16</p>
                <p className='s-hk'>E-mail apple@gmail.com</p>
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='border border-3 p-3 h-info'>
              <h5>有限会社Be-i</h5>
              <p className='s-hk'>京都府京都市</p>
              <p className='s-hk'>伏見区淀水垂町509-16</p>
              <p className='s-hk'>06(6943)1234</p>
            </div>
          </div>
        </div>
        <div className='row gx-2'>
          <div className='col-4'>
            <div className='border border-3 pe-3 ps-3 h-infos'>
              <h5>請求金額</h5>
            </div>
          </div>
          <div className='col-8'>
            <div className='border border-3 pe-3 ps-3 h-infos'>
              <h5>50000円</h5>
            </div>
          </div>
        </div>
        <div className='gx-2'>
          <div className='border border-3 p-4 h-infom'>
          <p style={{textAlign:'right'}}>2023-07-12</p>
            <div className='s-si'>
              <h5>請求詳細</h5>
              <hr/>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>40000円</h5>
              </div>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>10000円</h5>
              </div>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>10000円</h5>
              </div>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>20%</h5>
              </div>
              <hr />
              <div className='row'>
                <h5 className='col-6'>請求金額</h5>
                <h5 className='col-6'>70000円</h5>
              </div>
            </div>
            <p style={{textAlign:'right',marginTop:'30px'}}>発行者：石黒舞子</p>
          </div>
        </div>
        </div>
        </tbody>
        </table>
        </div>
        </div>
  )
}
