import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import baseURL from './config.js';


export const AccountUser = () => {

  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    Axios.get(`${baseURL}/api/get`)
      .then((response) => {
        const { users } = response.data;
        setUserList(users);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteUser = (company_pk) => {
    Axios.delete(`${baseURL}/api/delete/user/${company_pk}`)

    setUserList(userList.filter(user => user.company_pk !== company_pk));

  };

  const filteredUserList = userList.filter((val) => {
    const { company_name, status } = val;
    const searchRegex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    return (
      (searchTerm === '' || searchRegex.test(company_name)|| searchRegex.test(status))
    );
  })

  return (
    <div className='bo'>
    <Link to='/user/add'><button className='bt-csv' style={{ position: "absolute", right: 0, margin: '-60px 15px 0 0' }}><i className="fa-solcompany_pk fa-plus" style={{ color: "#ffffff" }}></i>登録</button></Link>

<div className='row bc m-3'>
  <div className="searchbar">
    <input type="search" className='search bt-s' placeholder="検索" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
    <button className="filter"><i className="fas fa-filter"></i></button>
  </div>
  <table className='bt-r'>
    <thead>
      <tr>
        <th>企業名</th>
        <th>ステータス</th>
        </tr>
      </thead>
      <tbody>
        {filteredUserList.map((val, index) => {
          return (
            <tr key={val.company_pk}>
              <td className='tx-l'><i className="fa-solcompany_pk fa-circle" style={{ color: "#19E415" }}></i>{val.company_name}</td>
              <td style={{padding:"10px"}}>{val.status}</td>
              <td style={{padding:"10px"}}>
                <button className='bt-h' style={{marginRight:"10px"}} onClick={() => deleteUser(val.company_pk)}>削除</button>
                <Link to={`/account/user/shousai?company_pk=${encodeURIComponent(val.company_pk)}`}><button className='bt-h'>基本情報</button></Link>
              </td>
            </tr>

              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
