import {Link, useLocation} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import baseURL from '../config.js';

export const DKigyouShousai = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('id');
  const [user, setUser] = useState([]);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    Axios.get(`${baseURL}/api/dshousai?id=${userId}`)
      .then((response) => {
        const { user } = response.data;
        setUser(user);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  const deleteUser = (id) => {
    Axios.delete(`${baseURL}/api/dkigyo/${id}`)

    setUserList(userList.filter(user => user.id !== id));

  };


  return (
    <div class="do">
      <div class="serchfield">
      <h2 class="kigyo fo-t">企業詳細</h2>
      </div>
      {user.map((val, index) => {
      return (
      <div class="row bc m-3" key={val.id}>
      <div class="shousaibar">
      <Link to="/ddashboard/kigyou">
      <button className="bt-h" style={{float:'left', marginLeft:'15%', fontSize:'20px',height:'40px'}}>戻る</button>
      </Link>
        <p style={{paddingRight:'25%',marginBottom:'0'}}>{val.username}</p>
        </div>
        <table>
        <tbody>
        <tr class="row m-2">
          <td class="col-3 "></td>
          <td class="col-2 p-2 label">仲介業者</td>
          <td class="col-7 p-2 iptfield"></td>
          <td class="col-3 "></td>
          <td class="col-2 p-2 label">郵便番号</td>
          <td class="col-7 p-2 iptfield">{val.postcode}</td>
          <td class="col-3 "></td>
          <td class="col-2 p-2 label">住所</td>
          <td class="col-7 p-2 iptfield">{val.address}</td>
          <td class="col-3 "></td>
          <td class="col-2 p-2 label">開始日</td>
          <td class="col-7 p-2 iptfield">{val.regdate}</td>
          <td class="col-3 "></td>
          <td class="col-2 p-2 label">TEL</td>
          <td class="col-7 p-2 iptfield">{val.tel}</td>
          <td class="col-3 "></td>
          <td class="col-2 p-2 label">プラン</td>
          <td class="col-7 p-2 iptfield">{val.plan}</td>
          <td class="col-12"><button class="cancel bt-h" type="submit"  onClick={() => deleteUser(val.id)}>解約</button></td>
        </tr>
        </tbody>
        </table>
        </div>
      )})}
      </div>
  )
}
