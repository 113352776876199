import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import baseURL from './config.js';

export const MeisaiRirekiShousai = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const hakkouBangou = queryParams.get('hakkou');
  const [user, setUser] = useState([])
  const [loader, setLoader] = useState(false);
  const [userDetails, setUserDetails] = useState([])

    const downloadPDF = () => {
      const capture = document.getElementsByClassName('fcon')[0]
      setLoader(true)
      html2canvas(capture).then((canvas)=>{
        const imgData = canvas.toDataURL('img.//png')
        const doc = new jsPDF('p', 'mm', 'a4')
        const componentWidth = doc.internal.pageSize.getWidth()
        const componentHeight = doc.internal.pageSize.getHeight()
        doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight)
        setLoader(false)
        doc.save('meisairireki.pdf')
      })
    }

  useEffect(() => {
  Axios.get(`${baseURL}/api/meisai/rireki/shousai?hakkou=${hakkouBangou}`)
        .then((response) => {
            const { meisaiRireki } = response.data;
          setUser(meisaiRireki);
          const userId = meisaiRireki[0].userID; // Get the userID from meisaiRireki
          console.log(userId)
  
  
          Axios.get(`${baseURL}/api/shousai?id=${userId}`)
            .then((response) => {
              const user = response.data.dairi;
              setUserDetails(user);
              console.log(user)
              console.log(userId)

  
            })
        })
        .catch((error) => {
          console.log(error);
        });
    }, [hakkouBangou]);

    


  return (
    <div className="bo">
      <h3 className='fo-t'>発行履歴</h3>
      <button className='bt-jc' onClick={() => navigate(-1)}>戻る</button>
      <button style={{ float: 'right', marginRight: '0px' }} className='pdf bt-jc' onClick={downloadPDF} disabled={!(loader===false)}>{loader?(<span>Downloading</span>):(<span>PDF</span>)}</button>
      {user.map((val, index) => {
        // const userData = JSON.parse(val.user);
            return (
      <div className='fcon' key={val.id}>
        <h3 className='s-title'>お支払い明細</h3>
        <div className='row gx-2'>
          <div className='col-6'>
            <div className='border border-3 p-3 h-info'>
              <div>
              {userDetails.length > 0 && ( // Add this condition
                <>
                  <h5>{userDetails[0].username}</h5>
                  <p className='s-hk'>〒{userDetails[0].postcode}</p>
                  <p className='s-hk'>{userDetails[0].address}</p>
                <p className='s-hk'>発行番号{val.hakkouBangou}</p>
                </>
              )}
              </div>
            </div>
            </div>
            <div className='col-6'>
            <div className='border border-3 p-3 h-info'>
              <h5>有限会社Be-i</h5>
              <p className='s-hk'>京都府京都市</p>
              <p className='s-hk'>伏見区淀水垂町509-16</p>
              <p className='s-hk'>06(6943)1234</p>
            </div>
          </div>
          </div>
          <div className='row gx-2'>
          <div className='col-4'>
            <div className='border border-3 pe-3 ps-3 h-infos'>
              <h5>請求金額</h5>
            </div>
          </div>
          <div className='col-8'>
            <div className='border border-3 pe-3 ps-3 h-infos'>
              <h5>50000円</h5>
            </div>
          </div>
        </div>
        <div className='gx-2'>
          <div className='border border-3 p-4 h-infom'>
            <p>{val.date}</p>
            <div className='s-si'>
              <h5>請求詳細</h5>
              <hr/>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>40000円</h5>
              </div>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>10000円</h5>
              </div>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>10000円</h5>
              </div>
              <div className='row'>
                <h5 className='col-6'>請求項目</h5>
                <h5 className='col-6'>10000円</h5>
              </div>
              <hr />
              <div className='row'>
                <h5 className='col-6'>請求金額</h5>
                <h5 className='col-6'>70000円</h5>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      );
            })}

    </div>
  )
}
 