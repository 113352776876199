import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './Auth'; 

export const Login = () => {
  const [admin_username, setUsername] = useState('');
  const [admin_password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || '/';
  const { login } = useAuth(); // Access the login function from the useAuth hook

  const handleLogin = async () => {
    try {
      await login(admin_username, admin_password); // Call the login function with the username and password
      navigate(redirectPath, { replace: true });
    } catch (error) {
      console.log("Login failed:", error);
      // Handle login error if needed
    }
  };
  return (
    <div className='App'>
      <div className='bg'>
        <div className="logo">
          <div className="left">Q</div>
          <div className="right">
            <div className="top">給与明細</div>
            <div className="bottom">システム</div>
          </div>
        </div>
        <div className='fcon' style={{ width: "40%", margin: "20px auto", padding: "20px 7%" }}>
          <h4>ログイン</h4>
          <form className='needs-validation' noValidate>
            <div>
              <label htmlFor='username' className='form-label'>ID</label>
              <input type='text' className='form-control' name='username' onChange={(e) => setUsername(e.target.value)} required></input>
              <div className='valid-feedback'>Looks good!</div>
              <div className='invalid-feedback'>Please write a valid input.</div>
            </div>
            <div style={{ paddingTop: "20px" }}>
              <label htmlFor='password' className='form-label'>パスワード</label>
              <input type='password' className='form-control' name='password' onChange={(e) => setPassword(e.target.value)} required></input>
              <div className='valid-feedback'>Looks good!</div>
              <div className='invalid-feedback'>Please write a valid input.</div>
            </div>
          </form>
          <button onClick={handleLogin} style={{ padding: "10px 30px", margin: "0" }}>ログイン</button>
          <span className="line">
              <Link to="/register">Sign Up</Link>
            </span>
        </div>
        <p className='cl'>Copyright 2023 Be-i.co,Ltd.</p>
        <p className='cl'>お問い合わせ先:<i className="fa-sharp fa-solid fa-phone"></i>03-6435-1785<i className="fa-solid fa-envelope"></i>info@be-i.co.jp</p>
      </div>
    </div>
  );
};