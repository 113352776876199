import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom'
import baseURL from '../config.js';

export const DKigyou = () => {
  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const kingaku = 70000;

  useEffect(() => {
    Axios.get(`${baseURL}/api/dkigyo`)
      .then((response) => {
        const { sousa } = response.data;
        setUserList(sousa);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filteredUserList = userList.filter((val) => {
    const { username, regdate, kingaku, status} = val;
    const searchRegex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
    return (
      (searchTerm === '' || searchRegex.test(username) || searchRegex.test(regdate) || searchRegex.test(kingaku) || searchRegex.test(status))
    );
  })

  return (
    <div>
      <div class="serchfield">
      <h2 class="kigyo fo-t">企業一覧</h2>
      <Link to={`/ddashboard/kigyou/kigyoushinki`}>
        <button class="newadd">+新規追加</button>
      </Link>
      </div>
      <div class="row bc m-3">
        <div class="searchbar">
      <input type="search" class='search bt-s' placeholder="検索" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
      <button class="filter"><i class="fas fa-filter"></i></button>
      </div>
      <tr class="row m-2">
      <td class="title col-3 text-center">企業名</td>
      <td class="title col-2 text-center">登録日</td>
      <td class="title col-2 text-center">金額</td>
      <td class="title col-2 text-center">ステータス</td>
      <td class="col-3"></td>
      </tr>
      <tbody>
        {filteredUserList.reverse().map((val, index) => {
          return (
            <tr key={val.id} class="row m-2">
              <td class="col-3 text-center">{val.username}</td>
              <td class="col-2 text-center">{val.regdate}</td>
              <td class="col-2 text-center">{kingaku}</td>
              <td class="col-2 text-center">{val.status}</td>
              <td class="col-3 text-center">
              <Link to={`/ddashboard/kigyou/kigyouShousai?id=${encodeURIComponent(val.id)}`}>
                <button className="Details">詳細</button>
              </Link>
              </td>
              </tr>
                );
              })}
              </tbody>
              </div>
            </div>
          )
        }